@import url(https://fonts.googleapis.com/css?family=Indie+Flower|Kalam);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


body {
  background: #282c34; }

div.container {
  display: grid;
  background: #282c34;
  grid-template-rows: 10% vh 90% vh; }

header {
  grid: 1;
  background: #7D719D;
  padding-left: 25px;
  border-bottom: 1px solid #AF92B0; }
  header h3 {
    width: 100%;
    color: #404040;
    text-align: center; }

.masonry {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1px;
  /* Adjustment for the gutter */
  width: 100%; }

.masonry-brick {
  flex: auto;
  height: 25vh;
  min-width: 15vw;
  max-width: 25vw;
  width: 25vw;
  margin: 0 1px 1px 0;
  /* Some gutter */ }

.masonry-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-filter: brightness(95%);
          filter: brightness(95%); }

body {
  background: #c33; }

header {
  border: none; }

.outer-container {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: minmax(0.5fr, 50px) 2fr 0.5fr 0.5fr;
  grid-template-areas: "heart1 header heart2" "main main main" ". signature ." "footer footer footer"; }

h1 {
  grid-area: header;
  color: white;
  font-family: 'Indie Flower', cursive;
  font-size: 2rem;
  margin: 0;
  text-align: center; }
  @media (min-width: 767px) {
    h1 {
      font-size: 3rem; } }

.main {
  grid-area: main;
  font-family: 'Kalam', cursive;
  color: white;
  padding: 10px;
  font-size: 1.5rem; }
  @media (min-width: 767px) {
    .main {
      padding: 25px; } }
  .main p {
    padding: 0;
    margin: 10px;
    line-height: 1.85rem; }
    @media (min-width: 767px) {
      .main p {
        margin: 20px; } }

p.signature {
  margin-left: 10%;
  font-size: 1.5rem;
  color: white;
  font-family: 'Kalam', cursive;
  grid-area: signature; }
  @media (min-width: 767px) {
    p.signature {
      margin-left: 50%;
      font-size: 1.75rem; } }
  p.signature span {
    margin-left: 10%;
    font-size: 2rem;
    line-height: 2rem; }

footer {
  grid-area: footer;
  font-family: 'Indie Flower', cursive;
  font-size: 1.5rem;
  color: white;
  margin-left: 55px;
  margin-bottom: 100px; }

.heart1 {
  grid-area: heart1; }

.heart2 {
  grid-area: heart2; }

.heart {
  fill: white;
  margin-left: 25%;
  margin-top: 25px;
  width: 25px;
  -webkit-animation: pulse 1s ease infinite;
          animation: pulse 1s ease infinite; }
  @media (min-width: 767px) {
    .heart {
      width: 50px; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

