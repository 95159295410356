@import url('https://fonts.googleapis.com/css?family=Indie+Flower|Kalam');
$tablet-width: 767px;

@mixin mobile {
    @media (max-width: #{$tablet-width -1px}) {
        @content;
    }
}

@mixin tablet-up {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

body {
    background: #c33;
}

header {
    border: none;
}

.outer-container {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: minmax(.5fr, 50px) 2fr .5fr .5fr;

    grid-template-areas:
        "heart1 header heart2"
        "main main main"
        ". signature ."
        "footer footer footer";
}

h1 {
    grid-area: header;
    color: white;
    font-family: 'Indie Flower', cursive;
    font-size: 2rem;

    @include tablet-up {
        font-size: 3rem;
    }

    margin: 0;
    text-align: center;
}

.main {
    grid-area: main;
    font-family: 'Kalam', cursive;
    color: white;
    padding: 10px;
    @include tablet-up {
        padding: 25px;
    }

    font-size: 1.5rem;

    p {
        padding: 0;
        margin: 10px;
        @include tablet-up {
            margin: 20px;
        }
        line-height: 1.85rem;
    }

}

p.signature {
    margin-left: 10%;
    font-size: 1.5rem;
    @include tablet-up {
        margin-left: 50%;
        font-size: 1.75rem;
    }
    color: white;
    font-family: 'Kalam', cursive;
    grid-area: signature;

    span {
        margin-left: 10%;
        font-size: 2rem;
        line-height: 2rem;
    }
}

footer {
    grid-area: footer;
    font-family: 'Indie Flower', cursive;
    font-size: 1.5rem;
    color: white;
    margin-left: 55px;
    margin-bottom: 100px;
}

.heart1 {
    grid-area: heart1;
}

.heart2 {
    grid-area: heart2;
}

.heart {
  fill: white;
  margin-left: 25%;
  margin-top: 25px;
  width: 25px;
  @include tablet-up {
    width: 50px;
  }
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}
