$black: #000000;
$dark-grey: #404040;
$dark-purple: #7D719D;
$purple: #AF92B0;
$white: #FFFFFF;

body {
  background: #282c34;
}

div.container {
    display: grid;
    background: #282c34;
    grid-template-rows: 10%vh 90%vh;
}

header {
    grid: 1;
    background: #7D719D;
    padding-left: 25px;
    border-bottom: 1px solid #AF92B0;
    h3 {
      width: 100%;
      color: $dark-grey;
      text-align: center;
    }
}


.masonry {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1px; /* Adjustment for the gutter */
  width: 100%;
}

.masonry-brick {
  flex: auto;
  height: 25vh;
  min-width: 15vw;
  max-width: 25vw;
  width: 25vw;
  margin: 0 1px 1px 0; /* Some gutter */
}

.masonry-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(95%);
}
